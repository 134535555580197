import { GenericEntitySearch } from '@resources/utils/generic-entity-search';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Column, Formatter, Formatters, OnEventArgs } from 'aurelia-slickgrid';
import { connectTo } from 'aurelia-store';
import { formatDistanceToNow, subSeconds } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { IEntity, ResultPager } from 'plugins/data-models';
import { pluck } from 'rxjs/operators';
import { ImpiloDb, ImpiloRepository } from 'services/repositories';
import { userSessionFullTextAction, userSessionSelectedRowAction } from 'services/state/actions';
import { IState } from 'services/state/state';
import { isDate } from '@resources/utils/other'
import * as UAParser from 'ua-parser-js';

const dateFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    if (isDate(value)) {
        const timeZone = getTimeZoneValue();
        const date = utcToZonedTime(subSeconds(new Date(value + 'Z'), +25), timeZone);

        // console.log('now', new Date(), date);

        return formatDistanceToNow(date, { addSuffix: true });
    } else {
        return '-';
    }
}

const userAgentFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    if (value) {
        const parser = new UAParser(value);

        if (parser.getBrowser().name == undefined) {
            console.log(parser.getDevice());
            return parser.getUA();
        } else {
            return parser.getBrowser().name + ' ' + parser.getBrowser().version + ' on ' + parser.getOS().name + ' ' + parser.getOS().version;
        }
    } else {
        return '-';
    }
}

@connectTo<IState>({
    selector: (store) => store.state.pipe(pluck('plugins', 'blister', 'fulltext', 'userSessions')),
    target: 'state'
})
@autoinject()
export class Index extends GenericEntitySearch {

    constructor(private readonly repository: ImpiloRepository, private readonly router: Router) {
        super();

        this.pager = new ResultPager(async (page: number, size: number) => {
            if (this.filter.trim().length >= 0) {
                this.isWaiting = true;
                const response = await this.repository.filterUserSession(this.filter, page, size);
                this.isWaiting = false;

                await userSessionSelectedRowAction();
                this.setSelectedRow(undefined);

                this.gridDataset = response.results.map((entity: ImpiloDb.Tables.UserSession) => {
                    return entity;
                });

                return response;
            }
        }, () => userSessionFullTextAction(this.filter, this.gridDataset, {
            from: this.pager.from,
            to: this.pager.to,
            itemsPerPage: this.pager.itemsPerPage,
            pageCount: this.pager.pageCount,
            pageNumber: this.pager.pageNumber,
            totalItems: this.pager.totalItems
        }));
    }

    async attachedDone() {
        if (this.pager) {
            this.pager.gotoFirst();
        }
    }

    // abstract defineGrid();
    defineGrid() {
        this.gridColumns = [
            { id: 'email', name: 'EMail', field: 'email', sortable: true },
            { id: 'pharmacy', name: 'Pharmacy', field: 'pharmacy', sortable: true },
            { id: 'fullname', name: 'Full Name', field: 'fullname', sortable: true },
            { id: 'lastAccessTime', name: 'Last Seen', field: 'lastAccessTime', sortable: true, formatter: dateFormatter },
            { id: 'remoteIpAddress', name: 'IP', field: 'remoteIpAddress', sortable: true },
            { id: 'userAgent', name: 'User Agent', field: 'userAgent', sortable: true, formatter: userAgentFormatter },
        ];

        this.gridOptions = {
            autoResize: {
                containerId: 'container-grid',
                calculateAvailableSizeBy: 'window',
                bottomPadding: 65
            },
            enableGridMenu: false,
            enableCellNavigation: true,
            enableRowSelection: true,
            checkboxSelector: {
                hideSelectAllCheckbox: true
            },
            rowSelectionOptions: {
                selectActiveRow: true,
            }
        };
    }

    // abstract editEntity(entity: IEntity): void;
    editEntity(entity: IEntity) {
    }

    // abstract newEntity(): void;
    newEntity() {
    }

    // abstract handleRowSelection(event, args);
    handleRowSelection(event, args) {
        return userSessionSelectedRowAction(args.rows[0]);
    }
}

function getTimeZoneValue() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

