import { autoinject } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import { Router, RouterConfiguration } from 'aurelia-router';
import { ToastrService } from 'services/toastr-service';

@autoinject()
export class App {

    private router: Router;

    constructor(private readonly toastr: ToastrService) {
    }

    public configureRouter(config: RouterConfiguration, router: Router) {
        config.title = '';
        config.map([
            { route: '', redirect: 'home' },
            { route: 'home', name: 'home', moduleId: PLATFORM.moduleName('./routes/home/index'), title: 'Home', nav: false },
            { route: 'pharmacies', name: 'pharmacies', moduleId: PLATFORM.moduleName('./routes/pharmacies/index'), title: 'Pharmacies', nav: true },
            { route: 'users', name: 'users', moduleId: PLATFORM.moduleName('./routes/users/index'), title: 'Users', nav: true },
            { route: 'sessions', name: 'sessions', moduleId: PLATFORM.moduleName('./routes/sessions/index'), title: 'Sessions', nav: true },
            { route: 'nappi', name: 'nappi', moduleId: PLATFORM.moduleName('./routes/nappi/index'), title: 'Nappi', nav: true },
        ]);
        this.router = router;
    }

    public attached() {
        console.log('Admin plugin loaded');
    }

}
