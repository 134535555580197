import { GenericEntitySearch } from '@resources/utils/generic-entity-search';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Formatters, OnEventArgs } from 'aurelia-slickgrid';
import { connectTo } from 'aurelia-store';
import { IEntity, ResultPager } from 'plugins/data-models';
import { pluck } from 'rxjs/operators';
import { ImpiloDb, ImpiloRepository } from 'services/repositories';
import { pharmacyFullTextAction, pharmacySelectedRowAction } from 'services/state/actions';
import { IState } from 'services/state/state';

@connectTo<IState>({
    selector: (store) => store.state.pipe(pluck('plugins', 'blister', 'fulltext', 'pharmacies')),
    target: 'state'
})
@autoinject()
export class Index extends GenericEntitySearch {

    private readonly entityRoute = 'pharmacy';

    constructor(private readonly repository: ImpiloRepository, private readonly router: Router) {
        super();

        this.pager = new ResultPager(async (page: number, size: number) => {
            if (this.filter.trim().length >= 0) {
                this.isWaiting = true;
                const response = await this.repository.filterPharmacy(this.filter, page, size);
                this.isWaiting = false;

                await pharmacySelectedRowAction();
                this.setSelectedRow(undefined);

                this.gridDataset = response.results.map((entity: ImpiloDb.Tables.Pharmacy) => {
                    return entity;
                });

                return response;
            }
        }, () => pharmacyFullTextAction(this.filter, this.gridDataset, {
            from: this.pager.from,
            to: this.pager.to,
            itemsPerPage: this.pager.itemsPerPage,
            pageCount: this.pager.pageCount,
            pageNumber: this.pager.pageNumber,
            totalItems: this.pager.totalItems
        }));
    }

    async attachedDone() {
        if (this.pager) {
            this.pager.gotoFirst();
        }
    }

    // abstract defineGrid();
    defineGrid() {
        this.gridColumns = [
            {
                id: 'edit',
                field: 'edit',
                excludeFromColumnPicker: true,
                excludeFromGridMenu: true,
                excludeFromHeaderMenu: true,
                formatter: Formatters.editIcon,
                minWidth: 30,
                maxWidth: 30,
                onCellClick: (e: Event, args: OnEventArgs) => {
                    const entity = args.dataContext as IEntity;
                    this.router.navigateToRoute(this.entityRoute, { guid: entity.guid });
                }
            },
            { id: 'name', name: 'Name', field: 'name', sortable: true },
            { id: 'contactPerson', name: 'Contact', field: 'contactPerson', sortable: true },
            { id: 'telephoneNumber', name: 'Telephone', field: 'telephoneNumber', sortable: true },
            { id: 'city', name: 'City', field: 'city', sortable: true },
            { id: 'isPrintingDisabled', name: 'No Print', field: 'isPrintingDisabled', sortable: true, formatter: Formatters.checkmark },
            { id: 'isActive', name: 'Active', field: 'isActive', sortable: true, formatter: Formatters.checkmark },
            { id: 'account', name: 'Account', field: 'account', sortable: true },
            { id: 'activePatients', name: 'Patients', field: 'activePatients', sortable: true },
            { id: 'activeUsers', name: 'Users', field: 'activeUsers', sortable: true },
            { id: 'treatmentPlans', name: 'Treatment Plans', field: 'treatmentPlans', sortable: true },
            { id: 'labels', name: 'Labels', field: 'labels', sortable: true },
        ];

        this.gridOptions = {
            autoResize: {
                containerId: 'container-grid',
                calculateAvailableSizeBy: 'window',
                bottomPadding: 65
            },
            enableGridMenu: false,
            enableCellNavigation: true,
            enableRowSelection: true,
            checkboxSelector: {
                hideSelectAllCheckbox: true
            },
            rowSelectionOptions: {
                selectActiveRow: true,
            }
        };
    }

    // abstract editEntity(entity: IEntity): void;
    editEntity(entity: IEntity) {
        this.router.navigateToRoute(this.entityRoute, { guid: entity.guid });
    }

    // abstract newEntity(): void;
    newEntity() {
        this.router.navigateToRoute(this.entityRoute, { guid: 'new' });
    }

    // abstract handleRowSelection(event, args);
    handleRowSelection(event, args) {
        return pharmacySelectedRowAction(args.rows[0]);
    }
}
