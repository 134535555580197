import { isGuid } from '@dts/scriptlib';
import { GenericEntitySearch } from '@resources/utils/generic-entity-search';
import { autoinject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Formatters, OnEventArgs } from 'aurelia-slickgrid';
import { connectTo, Store } from 'aurelia-store';
import { IEntity, ImpiloDb, ResultPager } from 'plugins/data-models';
import { pluck } from 'rxjs/operators';
import { AuthService } from 'services/auth-service';
import { IdentityRepository, ImpiloRepository } from 'services/repositories';
import { userFullTextAction, userSelectedRowAction } from 'services/state/actions';
import { IState } from 'services/state/state';
import { ToastrService } from 'services/toastr-service';

@connectTo<IState>({
    selector: (store) => store.state.pipe(pluck('plugins', 'admin', 'fulltext', 'users')),
    target: 'state'
})
@autoinject()
export class Index extends GenericEntitySearch {

    private pharmacies: ImpiloDb.Tables.Pharmacy[] = [];
    @observable private selectedPharmacy: Partial<ImpiloDb.Tables.Pharmacy> = null;
    @observable pharmacyPicker;

    constructor(private readonly repository: IdentityRepository, private readonly impiloRepository: ImpiloRepository, private readonly router: Router, private readonly authService: AuthService, private toastr: ToastrService) {
        super();

        this.pager = new ResultPager(async (page: number, size: number) => {

            const filter = this.filter;

            if (filter.trim().length >= 0) {
                this.isWaiting = true;

                const response = await this.repository.filterAspNetUserByPharmacy(filter, this.selectedPharmacy?.guid == '0' ? null : this.selectedPharmacy?.guid, page, size);
                this.isWaiting = false;

                await userSelectedRowAction();
                this.setSelectedRow(undefined);

                this.gridDataset = response.results.map((entity) => {
                    return entity;
                });

                return response;
            }
        }, () => userFullTextAction(this.filter, this.gridDataset, {
            from: this.pager.from,
            to: this.pager.to,
            itemsPerPage: this.pager.itemsPerPage,
            pageCount: this.pager.pageCount,
            pageNumber: this.pager.pageNumber,
            totalItems: this.pager.totalItems
        }));
    }

    selectedPharmacyChanged(newValue, oldValue) {
        if (this.pager && newValue) {
            return this.pager.gotoFirst();
        }
    }

    async attachedDone() {
        if (this.pager) {

            const pharmacies = (await (this.impiloRepository as ImpiloRepository).getPharmacies()).results.map((value) => {
                value.option = value.name;
                return value;
            });
            this.pharmacies = pharmacies;
            const all = new ImpiloDb.Tables.Pharmacy();
            all.guid = '0';
            all.name = '-';
            all['option'] = '-';

            this.pharmacies.unshift(all);

            // return this.pager.gotoFirst();
        }
    }

    blurred() {
        console.log('blur');
    }
    pharmacyPickerChanged() {
        this.pharmacyPicker.events.onShow = (e) => this.keyDownCallbackEnabled(false);
        this.pharmacyPicker.events.onHidden = (e) => {
            setTimeout(() => this.keyDownCallbackEnabled(true), 250);
        };
    }

    // abstract defineGrid();
    defineGrid() {
        this.gridColumns = [
            {
                id: 'edit',
                field: 'edit',
                excludeFromColumnPicker: true,
                excludeFromGridMenu: true,
                excludeFromHeaderMenu: true,
                formatter: Formatters.editIcon,
                minWidth: 30,
                maxWidth: 30,
                onCellClick: (e: Event, args: OnEventArgs) => {
                    const entity = args.dataContext;
                    this.router.navigateToRoute('user', { guid: entity.id });
                }
            },
            { id: 'userName', name: 'User Name', field: 'userName', sortable: true },
            { id: 'email', name: 'Email', field: 'email', sortable: true },
            { id: 'Fullname', name: 'Fullname', field: 'fullname', sortable: true },
            { id: 'phoneNumber', name: 'Phone', field: 'phoneNumber', sortable: true },
            { id: 'pharmacyName', name: 'Pharmacy', field: 'pharmacyName', sortable: true },
            { id: 'roleName', name: 'Role ', field: 'roleName', sortable: true },
            { id: 'isActive', name: 'Active', field: 'isActive', sortable: true, formatter: Formatters.checkmark },
            { id: 'isRoot', name: 'Owner', field: 'isRoot', sortable: true, formatter: Formatters.checkmark },
        ];

        this.gridOptions = {
            autoResize: {
                containerId: 'container-grid',
                calculateAvailableSizeBy: 'window',
                bottomPadding: 65
            },
            enableGridMenu: false,
            enableCellNavigation: true,
            enableRowSelection: true,
            checkboxSelector: {
                hideSelectAllCheckbox: true
            },
            rowSelectionOptions: {
                selectActiveRow: true,
            }
        };
    }

    // abstract editEntity(entity: IEntity): void;
    editEntity(entity) {
        console.log(this.pharmacyPicker);
        this.router.navigateToRoute('user', { guid: entity.id });
    }

    // abstract newEntity(): void;
    newEntity() {
        if (isGuid(this.selectedPharmacy.guid)) {
            this.router.navigateToRoute('user', { guid: 'new', pharmacyGuid: this.selectedPharmacy.guid, pharmacyName: this.selectedPharmacy.name });
        } else {
            this.toastr.warning('Please select a pharmacy first');
        }
    }

    // abstract handleRowSelection(event, args);
    handleRowSelection(event, args) {
        return userSelectedRowAction(args.rows[0]);
    }

    private readonly idMatcher = (a, b) => {
        if (a && b) {
            return a.id === b.id;
        }

        return false;
    }
}
